const neutralechoSettings = {
	title: "NeutralEcho News Network",
	description: "",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(210,209,203)',
	footerBg: "rgb(210,209,203)",
	headerTextColor: "#333",
	footerTextColor: "#333",
	style: 'analytical'
};

export default neutralechoSettings;
